import { MapContainer, TileLayer } from 'react-leaflet';
import { MapLeafletKml } from "./MapLeafletKml";
// eslint-disable-next-line
import Fullscreen from 'leaflet-fullscreen';

/**
 * The React Leaflet map. Note: including the key prop in MapContainer makes it redraw when the state changes.
*/
export function ReactLeafletMap({ stateInfo }) {
    return (
        <div className="react-leaflet-map">
            {stateInfo &&
             <h2>
                 {stateInfo.name} <a className="map-link" href={"/maps/"+stateInfo.code}>{"direct link to "+stateInfo.name+" map"}</a>
             </h2>
            }
            { stateInfo &&
              <MapContainer key={stateInfo.code}
                            center={[stateInfo.latitude,stateInfo.longitude]}
                            zoom={stateInfo.zoom}
                            style={{height: "600px"}}
                            scrollWheelZoom={true}
                            zoomControl={true}
                            attributionControl={true}
                            fullscreenControl={true} >
                  <MapLeafletKml stateInfo={stateInfo} />
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                             attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
              </MapContainer>
            }
        </div>
    );
}
