// an expandable stack of station updates
export function StationUpdates({ updates }) {
    return (
        <div className="station-updates">
            <ul>
                {updates.map(update => (
                    <li key={update.timeupdated}>
                        {update.removal
                         ? <div><b>{update.timeupdated} CT removed by {update.author}</b>: <i>{update.comment}</i></div>
                         : <div><b>{update.timeupdated} CT</b> {update.author}: <i>{update.comment}</i></div>
                        }
                    </li>
                ))}
            </ul>
        </div>
    );
}

