export function Footer() {
    return (
        <div className="page-footer">
            <div className="cc-logo">
                <a href="https://creativecommons.org/licenses/by-nc/3.0/">
                    <img src="https://licensebuttons.net/l/by-nc/3.0/88x31.png" alt="CC license logo" />
                </a>
            </div>
            <div className="mobile-app">
                <a href="https://www.pure-gas.org/download/rss.xml">
                    <img src="/Rss-feed.png" alt="RSS Feed" />
                </a>
            </div>
            <div className="mobile-app">
                <a href="https://itunes.apple.com/us/app/pure-gas/id454559068?ls=1&mt=8">
                    <img src="/pure-gas-app.png" alt="Pure-Gas iPhone app" />
                    <br/>
                    iPhone App
                </a>
            </div>
            <div className="mobile-app">
                <a href="https://play.google.com/store/apps/details?id=com.AutoLean.puregas">
                    <img src="/pure-gas-app.png" alt="Pure-Gas Android app" />
                    <br/>
                    Android App
                </a>
            </div>
            <div className="license">
                Pure-Gas.org is licensed under a <br/>
                <a href="https://creativecommons.org/licenses/by-nc/3.0/">Creative Commons Attribution-NonCommercial 3.0 Unported License.</a>
            </div>
            <div className="attrib">
                Loading spinners by <a href="https://loading.io/icon/">loading.io</a>
            </div>
        </div>
    );
}
