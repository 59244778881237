import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useQuery } from "@apollo/client";

import { GET_STATION } from "../graphql/queries";

import { ErrorBox } from "./ErrorBox";
import { StationMap } from "./StationMap";
import { StationInfo } from "./StationInfo";
import { StationUpdates } from "./StationUpdates";
import { StationUpdateForm } from "./StationUpdateForm";
import { StationRemoveForm } from "./StationRemoveForm";

// utility: form a KML URL for a station
function formatStationKmlUrl(id) {
    return process.env.REACT_APP_TOMCAT_URI + "/kml/" + id;
}

// utility: form a Google Map URL for a station
function formatStationGoogleUrl(station) {
    return "https://www.google.com/maps/@"+station.location.latitude+","+station.location.longitude+",17z";
}

// NOTE: station passed in may not contain updates, so requery here
export function Station({ state, station,
                          shownComponents,
                          onUpdateButtonClicked, onRemoveButtonClicked, onCancel, suppressButtons }) {
    const { loading, error, data } = useQuery(GET_STATION, {
        variables: { id: station.id }
    });
    if (loading) return ( <div className="loading">Loading station...</div> );
    if (error) return ( <ErrorBox error={error} /> );

    return (
        <div className="station-view">
            <div className="station-rightside">
                {data.station.location.latitude>0 && shownComponents.showMiniMap && 
                 <StationMap station={station} />
                }
                {data.station.location.latitude===0 && shownComponents.showMiniMap && 
                 <StationInfo station={station} />
                }
                {shownComponents.showUpdateForm &&
                 <StationUpdateForm state={state} station={station} onCancel={onCancel} />
                }
                {shownComponents.showRemoveForm &&
                 <StationRemoveForm station={station} onCancel={onCancel} />
                }
                {!data.station.removed && !shownComponents.showUpdateForm && !shownComponents.showRemoveForm && !suppressButtons &&
                 <div className="buttons">
                     <button className="update-choice" onClick={onUpdateButtonClicked}>update station</button>
                     <button className="remove-choice" onClick={onRemoveButtonClicked}>remove station</button>
                 </div>
                }
                {data.station.removed && !shownComponents.showUpdateForm &&
                 <div className="buttons">
                     <button className="update-choice" onClick={onUpdateButtonClicked}>restore station</button>
                 </div>
                }
            </div>
            {data.station.location.latitude ?
             <div className="gps-display">
                 GPS:
                 <span className="spacer"/>
                 <CopyToClipboard text={data.station.location.latitude+','+data.station.location.longitude}>
                     <button title="click to copy to clipboard" className="copy">{data.station.location.latitude+','+data.station.location.longitude}</button>
                 </CopyToClipboard>
                 <span className="spacer"/>
                 {data.station.location.comment}
                 <span className="spacer"/>
                 <div className="kml-icon">
                     KML: <a href={formatStationKmlUrl(data.station.id)}><img src="/ge.png" alt="Google Earth KML file" /></a>
                 </div>
                 <span className="spacer"/>
                 <div className="gm-icon">
                     Google: <a target="_blank" rel="noreferrer" href={formatStationGoogleUrl(station)}><img src="/gm.png" alt="Google Maps" /></a>
                 </div>
             </div>
             :
             <div className="gps-missing">GPS not available</div>
            }
            <div className="station-link">
                <a href={"/station/"+data.station.id}>direct link to this station</a>
            </div>
            {data.station.updates.length>0 && 
             <StationUpdates updates={data.station.updates} />
            }
            <div>
                <b>{data.station.timeposted} CT added by {data.station.author}</b>{data.station.comment && <span>: <i>{data.station.comment}</i></span>}
            </div>
            <div className="clear-both"/>
        </div>
    );
}


