export function Content() {
    return ( 
        <div>
            <p>
                If you buy ethanol-free gas, and your station isn't listed here, please add it now on the list for that state or province.
                </p>
                <p>
                    Please remove stations that no longer sell pure gas, even if you didn't post them. But before you remove a station,
                    <b>please double-check that it no longer sells E0!</b>
                    Jonathan winds up relisting about half of the stations that are removed because he calls them and finds that they still sell E0!
                    </p>
                    <p className="notice">
                        Franchises with pure gas at all locations (e.g. Irving Gas in Nova Scotia) are listed at the top of that state or province's listing.
                        </p>
                        <p>
                            <b>A note about phones:</b> about 80% of you are viewing the site on your phones, which is definitely not optimal.
                            I'll be working on making the site better on phones in the near future.
                            But in the meantime, please check it out on a computer to get the "user experience" I designed for.
                            </p>
                            <p>
                                Read the station comments carefully. The stations listed here vary in what sort of pure gas they offer.
                                If you've got a long drive ahead, give the station a call to check that they still sell what you want.
                                And please add a comment to the listing if you find out anything new!
                            </p>
        </div>
    );
}
