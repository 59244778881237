import { v4 as uuidv4 } from 'uuid';

import { Station } from "./Station";

// a given station displayed in a row
export function StationListRow({ state, station, stationId,
                                 shownComponents, onViewButtonClicked, suppressButtons,
                                 onUpdateButtonClicked, onRemoveButtonClicked, onCancel,
                                 onStationChecked, printable }) {
    return (
        <div className="station">
            {station.id === stationId && 
             <div className="station-top-border"></div>
            }
            {state && !printable &&
             <div className="checkbox"><input value={JSON.stringify(station)} type="checkbox" onChange={onStationChecked} /></div>
            }
            {!state &&
             <div className="state">{station.state.name}</div>
            }
            <div className="city">{station.city}</div>
            <div className="brand">{station.brand.name}</div>
            <div className="octanes">
                {station.octanes[0] ?
                 station.octanes.map(octane => (
                     <span key={uuidv4()} className="octane">{octane}</span>
                 ))
                 :
                 <span className="octane">&nbsp;</span>
                }
            </div>
            <div className="name">{station.name}</div>
            <div className="streetaddress">{station.streetaddress}</div>
            <div className="phone">
                { station.phone ? <span>{station.phone}</span> : <span>&nbsp;</span> }
            </div>
            <div className="lastupdate">
                { station.lastUpdate ? <span>{station.lastUpdate.substring(0,10)}</span> : <span>&nbsp;</span> }
            </div>
            {!printable &&
             <div className="view">
                 {!suppressButtons &&
                  <button key={station.id} value={station.id} onClick={onViewButtonClicked}>view</button>
                 }
             </div>
            }
            <div className="cleaner" />
            {station.id === stationId &&
             <Station state={state} station={station}
                      shownComponents={shownComponents} suppressButtons={suppressButtons}
                      onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel} />
            }
        </div>
    );
}
