export function StationListHeader({ showCheckbox }) {
    return (
        <div className="station station-list-header">
            {showCheckbox && 
             <div className="checkbox"></div>
            }
            <div className="city">town</div>
            <div className="brand">brand</div>
            <div className="octanes">octane</div>
            <div className="name">name</div>
            <div className="streetaddress">address</div>
            <div className="phone">phone</div>
            <div className="lastupdate">last update</div>
            <div className="details">&nbsp;</div>
            <div className="cleaner"></div>
        </div>
    );
}
