import { useQuery } from "@apollo/client";

import { GET_STATE } from "../graphql/queries";

import { ErrorBox } from "./ErrorBox";
import { StationAddForm } from "./StationAddForm";
import { StationList } from "./StationList";

// the state stats above the list of stations
export function State({ brands, stateCode, stationId,
                        shownComponents, onViewButtonClicked,
                        onAddButtonClicked, onUpdateButtonClicked, onRemoveButtonClicked, onCancel,
                        checkedStations, onStationChecked, onPrintStationsClicked }) {
    const { loading, error, data } = useQuery(GET_STATE, {
        variables: { code: stateCode }
    });
    if (loading) return ( <div className="loading">Loading state...</div> );
    if (error) return ( <ErrorBox error={error} /> );
    const selectedState = data.state;
    return (
        <div>
            <div className="state-name">{selectedState.name}: {selectedState.numStations} stations</div>
            <div className="state-link"><a href={selectedState.code}>direct link</a></div>
            <div className="add-button">
                If your E0 gas station isn't listed below, then<span className="spacer"/>
                <button onClick={onAddButtonClicked}>add a station</button>
            </div>
            <div className="cleaner"/>
            {shownComponents.showAddForm &&
             <StationAddForm state={selectedState} onCancel={onCancel} />
            }
            <StationList selectedState={selectedState} stationId={stationId}
                         shownComponents={shownComponents}
                         onViewButtonClicked={onViewButtonClicked}
                         onAddButtonClicked={onAddButtonClicked} onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel}
                         checkedStations={checkedStations} onStationChecked={onStationChecked} onPrintStationsClicked={onPrintStationsClicked} />
        </div>
    );
}
