export function ErrorBox({ error }) {
    return (
        <div className="error">
            {error.networkError &&
             <div>
                 <h3>{error.message}</h3>
                 {error.networkError.result && error.networkError.result.errors.map(err => (
                     <div key={err.extensions.code}>
                         <h3>{err.extensions.code}</h3>
                         <p>{err.message}</p>
                     </div>
                 ))}
             </div>
            }
            {error.clientErrors.map(err => (
                <div key={err.message}>
                    <h3>{err.message}</h3>
                    {err.extensions.response && err.extensions.response.body.errors && err.extensions.response.body.errors.map(rerr => (
                        <div key={rerr.details}>
                            <h3>{rerr.title}</h3>
                            <p>{rerr.details}</p>
                        </div>
                    ))}
                </div>
            ))}
            {error.graphQLErrors.map(err => (
                <div key={err.message}>
                    <h3>{err.message}</h3>
                    {err.extensions.response && err.extensions.response.body.errors && err.extensions.response.body.errors.map(rerr => (
                        <div key={rerr.details}>
                            <h3>{rerr.title}</h3>
                            <p>{rerr.details}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

