import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { REMOVE_STATION } from "../graphql/mutations";

import { ErrorBox } from "./ErrorBox";

export function StationRemoveForm({ station, onCancel }) {
    const { register, handleSubmit } = useForm();
    const [removeStation, { data, loading, error }] = useMutation(REMOVE_STATION);
    if (loading) return (
        <div className="loading">Removing station...</div>
    );
    const onSubmit = (d) => {
        removeStation({
            variables: {
                id: station.id,
                author: d.author,
                comment: d.comment,
            }
        });
    }
    return (
        <div>
            {error &&
             <ErrorBox error={error} />
            }
            {data
             ?
             <div className="message">
                 This station has been removed.<br/>
                 (Refresh your browser to see it vanish from this listing.)
             </div>
             :
             <form onSubmit={ handleSubmit(onSubmit) } className="station-form remove-trim">
                 <h3>
                     Please double-check that this station no longer sells E0!
                 </h3>
                 <div>
                     <label>Removed by<br/>
                     <input {...register("author")} required/></label>
                 </div>
                 <div>
                     <label>Why are you removing this station?<br/>
                     <textarea {...register("comment")} required></textarea></label>
                 </div>
                 <div className="buttons">
                     <button className="remove">REMOVE STATION</button>
                     <span className="spacer"/>
                     <button className="cancel" onClick={onCancel}>CANCEL</button>
                 </div>
             </form>
            }
        </div>
    );
}
