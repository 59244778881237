import { MapContainer, TileLayer } from 'react-leaflet';
import { Marker } from 'react-leaflet/Marker';
import { Popup } from 'react-leaflet/Popup';
// eslint-disable-next-line
import Fullscreen from 'leaflet-fullscreen';

// React Leaflet map of station
export function StationMap({ station }) {
    const center = [station.location.latitude, station.location.longitude];
    const position = [station.location.latitude+0.0001, station.location.longitude];
    return (
        <MapContainer center={ center }
                      style={{ height:"250px", width:"400px" }}
                      zoom={ 18 }
                      scrollWheelZoom={true}
                      zoomControl={true}
                      attributionControl={true}
                      fullscreenControl={true} >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                       attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
            <Marker position={position}>
                <Popup>
                    <b>{station.name}</b>
                    <div>
                        {station.brand.name}
                        {station.octanes.map(octane => (
                            <span key={octane} className="space-left">{octane}</span>
                        ))}
                    </div>
                    <div>
                        {station.streetaddress}
                    </div>
                    <div>
                        {station.city}, {station.state.code}
                    </div>
                    {station.phone &&
                     <div>
                         {station.phone}
                     </div>
                    }
                </Popup>
            </Marker>
        </MapContainer>
    );
}
