import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";

import { GET_STATS } from '../graphql/queries';

import { ErrorBox } from "./ErrorBox";
import { StateList } from "./StateList";
import { State } from "./State";
import { Content } from "./Content";
import { SelectedStationsPrintout } from "./SelectedStationsPrintout";
import { Updates } from "./Updates";

export function Home({ stateCode, stationId,
                       shownComponents,
                       onStateClicked, onViewButtonClicked,
                       onAddButtonClicked, onUpdateButtonClicked, onRemoveButtonClicked, onCancel,
                       checkedStations, onStationChecked, onPrintStationsClicked, printStations }) {
    // optional state code on URI
    var { id } = useParams();
    // query overall stats
    const { loading, error, data } = useQuery(GET_STATS);
    if (loading) return ( <div className="loading">Loading stats...</div> );
    if (error) return ( <ErrorBox error={error} /> );
    const stats = data.stats;
    // handle station ID on URI
    if (id) {
        stateCode = id;
    }
    return (
        <div>
            {printStations ?
             <SelectedStationsPrintout checkedStations={checkedStations} />
             :
             <div className="content">
                 <h1>Welcome to the definitive list of stations that sell ethanol-free gasoline in the U.S. and Canada!</h1>
                 {stats &&
                  <div className="station-count">
                      We currently have <b>{stats.stationCount}</b> stations (<b>{stats.addedLastWeek}</b> added and <b>{stats.removedLastWeek}</b> removed in the past seven days).
                  </div>
                 }
                 {!id &&
                  <StateList onStateClicked={onStateClicked} />
                 }
                 {stateCode
                  ?
                  <State stateCode={stateCode} stationId={stationId}
                         shownComponents={shownComponents}
                         onViewButtonClicked={onViewButtonClicked}
                         onAddButtonClicked={onAddButtonClicked} onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel}
                         checkedStations={checkedStations} onStationChecked={onStationChecked} onPrintStationsClicked={onPrintStationsClicked} />
                  :
                  <div className="station-count">
                      To add a station, first click on the state or province above; 
                      then click the <img style={{"vertical-align": "middle"}} src="/add-a-station-button.png" alt="add a station button"/> button 
                      that will appear.
                  </div>
                 }
                 <Updates />
                 <Content />
             </div>
            }
        </div>
    );
}
