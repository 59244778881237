/**
 * A map of code to name, center location, and zoom level for states and provinces.
 */
export const STATES_MAP = new Map([
    ['AB',{name:'Alberta',code:'AB',latitude:53.5,longitude:-115,zoom:7}],
    ['AK',{name:'Alaska',code:'AK',latitude:64.0685,longitude:-152.2782,zoom:7}],
    ['AL',{name:'Alabama',code:'AL',latitude:32.7794,longitude:-86.8287,zoom:7}],
    ['AR',{name:'Arkansas',code:'AR',latitude:34.8938,longitude:-92.4426,zoom:7}],
    ['AZ',{name:'Arizona',code:'AZ',latitude:34.2744,longitude:-111.6602,zoom:7}],
    ['BC',{name:'British Columbia',code:'BC',latitude:52.5,longitude:-122.55,zoom:7}],
    ['CA',{name:'California',code:'CA',latitude:37.1841,longitude:-119.4696,zoom:7}],
    ['CO',{name:'Colorado',code:'CO',latitude:38.9972,longitude:-105.5478,zoom:7}],
    ['CT',{name:'Connecticut',code:'CT',latitude:41.6219,longitude:-72.7273,zoom:9}],
    ['DC',{name:'District of Columbia',code:'DC',latitude:38.9101,longitude:-77.0147,zoom:9}],
    ['DE',{name:'Delaware',code:'DE',latitude:38.9896,longitude:-75.505,zoom:9}],
    ['FL',{name:'Florida',code:'FL',latitude:28.0,longitude:-83.4497,zoom:7}],
    ['GA',{name:'Georgia',code:'GA',latitude:33.0,longitude:-83.4426,zoom:7}],
    ['HI',{name:'Hawaii',code:'HI',latitude:20.5,longitude:-158.0,zoom:7}],
    ['IA',{name:'Iowa',code:'IA',latitude:42.0751,longitude:-93.496,zoom:7}],
    ['ID',{name:'Idaho',code:'ID',latitude:46.0,longitude:-115.0,zoom:7}],
    ['IL',{name:'Illinois',code:'IL',latitude:40.0417,longitude:-89.1965,zoom:7}],
    ['IN',{name:'Indiana',code:'IN',latitude:39.8942,longitude:-86.2816,zoom:7}],
    ['KS',{name:'Kansas',code:'KS',latitude:38.4937,longitude:-98.3804,zoom:7}],
    ['KY',{name:'Kentucky',code:'KY',latitude:37.5347,longitude:-85.3021,zoom:7}],
    ['LA',{name:'Louisiana',code:'LA',latitude:31.0689,longitude:-91.9968,zoom:7}],
    ['MA',{name:'Massachusetts',code:'MA',latitude:42.2596,longitude:-71.8083,zoom:8}],
    ['MB',{name:'Manitoba',code:'MB',latitude:51.0,longitude:-98.0,zoom:7}],
    ['MD',{name:'Maryland',code:'MD',latitude:39.055,longitude:-76.7909,zoom:8}],
    ['ME',{name:'Maine',code:'ME',latitude:45.3695,longitude:-69.2428,zoom:7}],
    ['MI',{name:'Michigan',code:'MI',latitude:44.3467,longitude:-87.0,zoom:7}],
    ['MN',{name:'Minnesota',code:'MN',latitude:46.2807,longitude:-94.3053,zoom:7}],
    ['MO',{name:'Missouri',code:'MO',latitude:38.3566,longitude:-92.458,zoom:7}],
    ['MS',{name:'Mississippi',code:'MS',latitude:32.7364,longitude:-89.6678,zoom:7}],
    ['MT',{name:'Montana',code:'MT',latitude:47.0527,longitude:-109.6333,zoom:7}],
    ['NB',{name:'New Brunswick',code:'NB',latitude:46.34,longitude:-66.42,zoom:7}],
    ['NC',{name:'North Carolina',code:'NC',latitude:35.5557,longitude:-79.3877,zoom:7}],
    ['ND',{name:'North Dakota',code:'ND',latitude:47.4501,longitude:-100.4659,zoom:7}],
    ['NE',{name:'Nebraska',code:'NE',latitude:41.5378,longitude:-99.7951,zoom:7}],
    ['NF',{name:'Newfoundland and Labrador',code:'NF',latitude:50.49,longitude:-60.51,zoom:7}],
    ['NH',{name:'New Hampshire',code:'NH',latitude:43.6805,longitude:-71.5811,zoom:8}],
    ['NJ',{name:'New Jersey',code:'NJ',latitude:40.1907,longitude:-74.6728,zoom:9}],
    ['NM',{name:'New Mexico',code:'NM',latitude:34.4071,longitude:-106.1126,zoom:7}],
    ['NS',{name:'Nova Scotia',code:'NS',latitude:45.23,longitude:-63,zoom:7}],
    ['NT',{name:'Northwest Territories',code:'NT',latitude:69.38,longitude:-119.21,zoom:5}],
    ['NV',{name:'Nevada',code:'NV',latitude:39.3289,longitude:-116.6312,zoom:7}],
    ['NY',{name:'New York',code:'NY',latitude:42.9538,longitude:-75.5268,zoom:7}],
    ['OH',{name:'Ohio',code:'OH',latitude:40.2862,longitude:-82.7937,zoom:7}],
    ['OK',{name:'Oklahoma',code:'OK',latitude:35.5889,longitude:-97.4943,zoom:7}],
    ['ON',{name:'Ontario',code:'ON',latitude:45.1 ,longitude:-79.2,zoom:7}],
    ['OR',{name:'Oregon',code:'OR',latitude:43.9336,longitude:-120.5583,zoom:7}],
    ['PA',{name:'Pennsylvania',code:'PA',latitude:40.8781,longitude:-77.7996,zoom:8}],
    ['PE',{name:'Prince Edward Island',code:'PE',latitude:46.51,longitude:-63.19,zoom:9}],
    ['QC',{name:'Québec',code:'QC',latitude:47.2 ,longitude:-74.9,zoom:7}],
    ['RI',{name:'Rhode Island',code:'RI',latitude:41.6762,longitude:-71.5562,zoom:9}],
    ['SC',{name:'South Carolina',code:'SC',latitude:33.9169,longitude:-81.0,zoom:8}],
    ['SD',{name:'South Dakota',code:'SD',latitude:44.4443,longitude:-100.2263,zoom:7}],
    ['SK',{name:'Saskatchewan',code:'SK',latitude:51.0,longitude:-105.68,zoom:7}],
    ['TN',{name:'Tennessee',code:'TN',latitude:35.858,longitude:-86.3505,zoom:8}],
    ['TX',{name:'Texas',code:'TX',latitude:31.4757,longitude:-99.3312,zoom:7}],
    ['UT',{name:'Utah',code:'UT',latitude:39.5,longitude:-111.6703,zoom:7}],
    ['VA',{name:'Virginia',code:'VA',latitude:37.5215,longitude:-78.8537,zoom:7}],
    ['VT',{name:'Vermont',code:'VT',latitude:43.9,longitude:-72.6658,zoom:8}],
    ['WA',{name:'Washington',code:'WA',latitude:47.3826,longitude:-120.4472,zoom:7}],
    ['WI',{name:'Wisconsin',code:'WI',latitude:44.8,longitude:-89.9941,zoom:7}],
    ['WV',{name:'West Virginia',code:'WV',latitude:38.6409,longitude:-80.6227,zoom:8}],
    ['WY',{name:'Wyoming',code:'WY',latitude:42.9957,longitude:-107.5512,zoom:7}],
    ['YT',{name:'Yukon',code:'YT',latitude:63.0,longitude:-132.41,zoom:7}]
]);
