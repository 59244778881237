import "./map-spinner.css";

export function MapLoadingSpinner() {
    return (
        <div className="map-spinner">
            <div className="loadingio-spinner-spinner-5yhvgvplig7">
                <div className="ldio-s76nu2h3ncs">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        </div>
    );
}
