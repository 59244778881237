import { v4 as uuidv4 } from 'uuid';

export function SelectedStationsPrintout({ checkedStations }) {
    const stations = [];
    for (var i = 0; i < checkedStations.length; i++) {
        stations.push(JSON.parse(checkedStations[i]));
    }
    return (
        <div className="stations-printout">
            <div className="header">
                Pure-Gas.org: selected stations in {stations[0].state.name}
            </div>
            <div className="station-printout station-printout-header">
                <div className="city">&nbsp;town</div>
                <div className="brand">brand</div>
                <div className="octanes">octanes</div>
                <div className="name">name</div>
                <div className="streetaddress">address</div>
                <div className="phone">phone</div>
                <div className="lastupdate">last update</div>
                <div className="cleaner"></div>
            </div>
            {stations.map(station => (
                <div key={station.id} className="station-printout">
                    <div className="city">&nbsp;{station.city}</div>
                    <div className="brand">{station.brand.name}</div>
                    <div className="octanes">
                        {station.octanes[0] ?
                         station.octanes.map(octane => (
                             <span key={uuidv4()} className="octane">{octane}</span>
                         ))
                         :
                         <span className="octane">&nbsp;</span>
                        }
                    </div>
                    <div className="name">{station.name}</div>
                    <div className="streetaddress">{station.streetaddress}</div>
                    <div className="phone">
                        {station.phone ?
                         <span>{station.phone}</span>
                         :
                         <span>&nbsp;</span>
                        }
                    </div>
                    <div className="lastupdate">
                        {station.lastUpdate ?
                         <span>{station.lastUpdate.substring(0,10)}</span>
                         :
                         <span>&nbsp;</span>
                        }
                    </div>
                    <div className="cleaner" />
                </div>
            ))}
            <div className="license">
                Pure-Gas.org is licensed under a Creative Commons Attribution-NonCommercial 3.0 Unported License.
            </div>
            <div className="landscape">
                best printed in landscape mode
            </div>
        </div>
    );
}
