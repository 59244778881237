import { useParams } from 'react-router-dom';

import { useQuery } from "@apollo/client";

import { GET_STATION } from "../graphql/queries";

import { StationListRow } from "./StationListRow";
import { ErrorBox } from "./ErrorBox";

export function SingleStation({ brands, octanes,
                                shownComponents,
                                onUpdateButtonClicked, onRemoveButtonClicked, onCancel }) {
    // handle station ID on URI
    var { id } = useParams();
    // query the station for full data
    const { loading, error, data } = useQuery(GET_STATION, {
        variables: { id: id }
    });
    if (loading) return (
        <div className="loading">
            Loading station {id}...
        </div>
    );
    if (error) return <ErrorBox error={error} />;
    return (
        <div className="content">
            <div className="station-list">
                <div className="station header">
                    <div className="city">town</div>
                    <div className="brand">brand</div>
                    <div className="octanes">octane</div>
                    <div className="name">name</div>
                    <div className="streetaddress">address</div>
                    <div className="phone">phone</div>
                    <div className="lastupdate">last update</div>
                    <div className="details">&nbsp;</div>
                    <div className="cleaner"></div>
                </div>
            </div>
            <div className="station-list">
                <StationListRow station={data.station} stationId={id} 
                                brands={brands} octanes={octanes}
                                shownComponents={shownComponents}
                                onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel} />
            </div>
        </div>
    );
}

