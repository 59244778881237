import { useQuery } from "@apollo/client";

import { GET_BRANDS } from '../graphql/queries';

import { ErrorBox } from "./ErrorBox";

export function BrandSelector({ register, station }) {
    // query brands
    const { loading, error, data } = useQuery(GET_BRANDS);
    if (loading) return ( <div className="loading">Loading brands...</div> );
    if (error) return ( <ErrorBox error={error} /> );
    const brands = data.brands;
    return (
        station ?
            <select {...register("brand_id")} defaultValue={station.brand.id}>
                {brands.map(brand => (
                    <option key={brand.id} value={brand.id}>{brand.name}</option>
                ))}
            </select>
        :
        <select {...register("brand_id")}>
            {brands.map(brand => (
                <option key={brand.id} value={brand.id}>{brand.name}</option>
            ))}
        </select>
    );
}
        
