// info box in lieu of StationMap
export function StationInfo({ station }) {
    return (
        <div className="station-info">
            <b>{station.name}</b>
            <div>
                {station.brand.name}
                {station.octanes.map(octane => (
                    <span key={octane} className="space-left">{octane}</span>
                ))}
            </div>
            <div>
                {station.streetaddress}
            </div>
            <div>
                {station.city}, {station.state.code}
            </div>
            {station.phone &&
             <div>
                 {station.phone}
             </div>
            }
        </div>
    );
}
