import "./spinner.css";

export function StationsLoading({ state }) {
    return (
        <div className="station-list">
            <div className="spinner-box">
                {state
                 ?
                 <h2>{state.name} loading...</h2>
                 :
                 <h2 className="spinleft">Stations loading...</h2>
                }
                <div className="spinner">
                    <div className="loadingio-spinner-ellipsis-gnzf74rssp">
                        <div className="ldio-528ng4tcaf">
                            <div></div><div></div><div></div><div></div><div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
