import {
  gql
} from "@apollo/client";

export const GET_STATS = gql`
query Stats {
  stats {
    stationCount
    addedLastWeek
    removedLastWeek
  }
}
`;

export const GET_BRANDS = gql`
query Brands {
  brands {
    id
    name
  }
}
`;

export const GET_STATES = gql`
query States {
  states {
    code
    name
    numStations
    numAddedLastMonth
    numRemovedLastMonth
  }
}
`;

export const GET_STATE = gql`
query State($code: ID!) {
  state(code: $code) {
    code
    name
    numStations
    numAddedLastMonth
    numRemovedLastMonth
    location {
      latitude
      longitude
    }
  }
}
`;

export const GET_STATION_LIST = gql`
query StationsByState($code: ID!) {
  stationsByState(code: $code) {
    id
    name
    streetaddress
    city
    state {
      code
      name
    }
    phone
    brand {
      id
      name
    }
    location {
      latitude
      longitude
      comment
    }
    octanes
    timeposted
    removed
    comment
    author
    lastUpdate
  }
}
`;

export const GET_REMOVED_LIST = gql`
query RemovedStations {
  removedStations {
    id
    name
    streetaddress
    city
    state {
      code
      name
    }
    phone
    brand {
      id
      name
    }
    location {
      latitude
      longitude
      comment
    }
    octanes
    lastUpdate
  }
}
`;

export const GET_STATION = gql`
query Station($id: ID!) {
  station(id: $id) {
    id
    name
    streetaddress
    city
    state {
      code
      name
    }
    phone
    brand {
      id
      name
    }
    location {
      latitude
      longitude
      comment
    }
    octanes
    timeposted
    removed
    comment
    author
    lastUpdate
    updates {
      removal
      comment
      author
      timeupdated
    }
  }
}
`;
