import ReactLeafletKml from 'react-leaflet-kml';
import { useEffect, useState } from 'react';
import { useMap, useMapEvents } from "react-leaflet";
import { MapLoadingSpinner } from "./MapLoadingSpinner";

/**
 * We wrap ReactLeafletKml in this component so we can call useMap() to get viewport bounding box for KML, and useMapEvents().
 */
export function MapLeafletKml() {
    const map = useMap();
    const [getKml, setGetKml] = useState(true);
    const [kml, setKml] = useState(null);
    const [key, setKey] = useState(null);
    useMapEvents({
        // moveend also fires on zoom change
        moveend() {
            setGetKml(true);
        },
    });
    useEffect(() => {
        if (getKml) {
            const bounds = map.getBounds();
            const url = process.env.REACT_APP_TOMCAT_URI + "/kml" +
                "?swLat="+bounds["_southWest"].lat +
                "&swLng="+bounds["_southWest"].lng +
                "&neLat="+bounds["_northEast"].lat +
                "&neLng="+bounds["_northEast"].lng;
            fetch(url)
                .then((res) => res.text())
                .then((kmlText) => {
                    // parse and set the new KML data
                    const parser = new DOMParser();
                    setKml(parser.parseFromString(kmlText, "text/xml"));
                    // new key will force ReactLeafletKml redraw
                    setKey(bounds["_southWest"].lat + bounds["_southWest"].lng + bounds["_northEast"].lat + bounds["_northEast"].lng);
                });
            // don't get KML again unless we get a qualifying event
            setGetKml(false);
            // info for tweaking state map centers
            // console.log(map.getCenter());
        }
    }, [getKml, map]);
    return (
        <div>
            {kml ? (
                <ReactLeafletKml key={key} kml={kml} />
            ) : (
                <MapLoadingSpinner />
            )}
        </div>
    );
}
