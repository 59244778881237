import { useQuery } from "@apollo/client";

import { GET_REMOVED_LIST } from "../graphql/queries";

import { StationListHeader } from "./StationListHeader";
import { StationListRow } from "./StationListRow";
import { StationsLoading } from "./StationsLoading";
import { ErrorBox } from "./ErrorBox";

// the entire list of stations
export function RemovedList({ stationId, 
                              brands, octanes,
                              shownComponents,
                              onViewButtonClicked, 
                              onUpdateButtonClicked, onCancel }) {
    // query the recently removed stations
    const { loading, error, data } = useQuery(GET_REMOVED_LIST);
    if (loading) return <StationsLoading />
    if (error) return <ErrorBox error={error} />;
    return (
        <div className="content">
            <h1>Recently Removed Stations</h1>
            <StationListHeader showCheckbox={false} />
            <div className="station-list">
                {data.removedStations.map(station => (
                    <StationListRow key={station.id} station={station} stationId={stationId} 
                                    brands={brands} octanes={octanes}
                                    shownComponents={shownComponents}
                                    onViewButtonClicked={onViewButtonClicked}
                                    onUpdateButtonClicked={onUpdateButtonClicked} onCancel={onCancel} />
                ))}
            </div>
        </div>
    );
}
