import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { ADD_STATION } from "../graphql/mutations";
import { OCTANES } from "../util/octanes";

import { BrandSelector } from "./BrandSelector";
import { ErrorBox } from "./ErrorBox";
import { StationListHeader } from "./StationListHeader";
import { StationListRow } from "./StationListRow";
import { StationUpdateForm } from "./StationUpdateForm";

export function StationAddForm({ state, onCancel }) {
    const { register, handleSubmit } = useForm();
    // add station
    const [addStation, { data, loading, error }] = useMutation(ADD_STATION);
    if (loading) return ( <div className="loading">Adding station...</div> );
    if (error) return ( <ErrorBox error={error} /> );
    const onSubmit = (d) => {
        // get the non-empty octanes, use alert box if none provided
        const octaneList = [];
        parseInt(d.octane0) && octaneList.push(parseInt(d.octane0));
        parseInt(d.octane1) && octaneList.push(parseInt(d.octane1));
        parseInt(d.octane2) && octaneList.push(parseInt(d.octane2));
        octaneList.length>0 ?
            addStation({
                variables: {
                    brandId: parseInt(d.brand_id),
                    name: d.name,
                    streetaddress: d.streetaddress,
                    city: d.city,
                    stateCode: state.code,
                    author: d.author,
                    octanes: octaneList,
                    comment: d.comment,
                    phone: d.phone,
                    latitude: parseFloat(d.latitude),
                    longitude: -parseFloat(d.longitude),
                    gpscomment: d.gpscomment,
                }
            })
            :
            alert("You must select at least one octane rating.");
        ;
    }
    return (
        <div>
            {data && data.addStation.station.removed &&
             <StationUpdateForm state={state} station={data.addStation.station} onCancel={onCancel} reactivate={true} />
            }
            {data && !data.addStation.station.removed &&
             <div>
                 <div className="thanks">
                     Thanks for adding this station, {data.addStation.station.author}! <span className="normal">Refresh the page to see it appear in the {state.code} list.
                     Don't forget to double-check the GPS location, update after a page refresh if needed!</span>
                 </div>
                 <StationListHeader />
                 <StationListRow state={state} station={data.addStation.station} stationId={data.addStation.station.id} suppressButtons={true}
                          shownComponents={{showUpdateForm:false, showRemoveForm:false, showAddForm:false, showMiniMap:true}} />
             </div>
            }
            {!data &&
             <form onSubmit={ handleSubmit(onSubmit) } className="station-form add-trim">
                 <label>
                     Brand<br/>
                     <BrandSelector register={register} />
                 </label>
                 <label>
                     Station name <span className="normal">(just the name)</span><br/>
                     <input {...register("name")} required />
                 </label>
                 <label>
                     Street address <span className="normal">(number and street only)</span><br/>
                     <input {...register("streetaddress")} required />
                 </label>
                 <label>
                     City/Town <span className="normal">(without state or zip) in</span> {state.name}<br/>
                     <input {...register("city") } required />
                 </label>
                 <label>
                     <span className="normal">Phone (format: 123-456-7890)</span><br/>
                     <input {...register("phone")} />
                 </label>
                 <label>
                     Octanes <span className="normal">(select at least one)</span><br/>
                     <select {...register("octane0")} required >
                         <option key="o00" value={0}>--</option>
                         {OCTANES.map(octane => (
                             <option key={'o0'+octane} value={octane}>{octane}</option>
                         ))}
                     </select>
                     <span className="spacer"/>
                     <select {...register("octane1")}>
                         <option key="o10" value={0}>--</option>
                         {OCTANES.map(octane => (
                             <option key={'o1'+octane} value={octane}>{octane}</option>
                         ))}
                     </select>
                     <span className="spacer"/>
                     <select {...register("octane2")}>
                         <option key="o20" value={0}>--</option>
                         {OCTANES.map(octane => (
                             <option key={'o2'+octane} value={octane}>{octane}</option>
                         ))}
                     </select>
                 </label>
                 <label>
                     <span className="normal">GPS</span><br/>
                     <input {...register("latitude")} className="gps-input"/> <span className="normal">N</span>
                     <span className="spacer" />
                     <input {...register("longitude")} className="gps-input" /> <span className="normal">W (positive number)</span>
                 </label>
                 <label>
                     <span className="normal">How did you get these GPS coordinates?</span><br/>
                     <textarea {...register("gpscomment")} />
                 </label>
                 <label>
                     Your name<br/>
                     <input {...register("author")} required />
                 </label>
                 <label>
                     <span className="normal">Notes about this station</span><br/>
                     <textarea {...register("comment")}></textarea>
                     <div className="buttons">
                         <button className="add">ADD STATION</button>
                         <span className="spacer"/>
                         <button className="cancel" onClick={onCancel}>CANCEL</button>
                     </div>
                 </label>
             </form>
            }
        </div>
    );
}
