import { STATES_MAP } from '../util/statesmap';

// clickable list of states
export function StateList({ onStateClicked }) {
    return (
        <div className="state-list">
          {[...STATES_MAP].map(([key, state]) => (
              <button key={key} title={state.name} value={state.code} onClick={onStateClicked}>{state.code}</button>
          ))}
        </div>
    );
}
