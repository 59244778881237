import { Link } from "react-router-dom";
import { DonateButton } from './DonateButton';

export function Header() {
    return (
        <div>
          <div className="page-header">
            <Link to="/" className=""><img src="/pure-gas-logo-cropped.png" alt="pure-gas.org" /></Link>
            <div className="navbar">
              <ul>
                <li>
                  <DonateButton/>
                </li>
                <li>
                  <Link to="/about" className="">about this site</Link>
                </li>
                <li>
                  <Link to="/kml" className="">KML file</Link>
                </li>
                <li>
                  <Link to="/poi" className="">POI file</Link>
                </li>
                <li>
                  <Link to="/maps" className="">maps</Link>
                </li>
                <li>
                  <Link to="/" className="">stations</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
    );
}
