export function AboutPage() {
    return (
        <div className="content">
            <h1>About this site</h1>

            <p>
              <b>pure-gas.org</b> is a site dedicated to one, simple task: to list the gas stations in the U.S. and Canada that serve ethanol-free gasoline.
            </p>

            <h2>Why pure gas?</h2>
            <p>
                Many cars, motorcycles, boats, aircraft and tools have engines that run worse, or have parts that deteriorate, when run on gasoline that contains ethanol.
                In addition, ethanol leaves residue on valves and other parts that can hinder performance.
            </p>

            <h2>Upload a station!</h2>
            <p>
                This site started in the summer of 2009, and always needs more station uploads! Please enter ethanol-free stations that you know about.
            </p>

            <h2>Download a POI file!</h2>
            <p>
              If you've got a GPS navigator, chances are you can load a CSV POI file into it. Dowload the POI CVS file and give it a try!
            </p>

            <h2>Download a KML file!</h2>
            <p>
              Or, if you like to play with Google Earth or other apps that use KML files, download the KML file.
            </p>

            <h2>Install the mobile app!</h2>
            <p>
              Install the <a href="https://itunes.apple.com/us/app/pure-gas/id454559068?ls=1&mt=8">iPhone app</a> from the iTunes store,
              or the <a href="https://play.google.com/store/apps/details?id=com.AutoLean.puregas">Android app</a> from Google Play.
              The mobile apps were written by Jeff Jackson and Bobby Rehm of AutoLean, Inc. Thanks, Jeff and Bobby!!
            </p>

            <h2>Are certain brands always ethanol-free?</h2>
            <p>
              No. All gasoline brands have both pure and ethanol-containing gasoline under the same brand names. For example, Shell V-Power ranges from 91 to 93 octane both with and without added ethanol.
              It just varies from station to station, and it's up to the station owner whether or not to sell pure gas.
              Often times you'll find a station with, say, 91-octane 10% ethanol gas at most pumps, but which has a single pump, at a higher price, dispensing pure 91-octane gas.
            </p>

            <h2>How do I know if a particular gas and grade is ethanol-free?</h2>
            <p>
              In most states, stations are required to label the pumps if they contain ethanol over a certain percentage, which varies by state but is usually 1 percent.
              A few states have no labeling requirement, and you just have to ask someone at the station that knows what goes in the tanks.
              State-by-state labeling requirements are listed at <a href="http://fuel-testers.com/state_guide_ethanol_laws.html">fuel-testers.com</a>.
            </p>

            <h2>Can I test gas myself to see if it contains ethanol?</h2>
            <p>
              Yes, and it's quite easy to do. Inexpensive fuel-testing kits are widely available, or you can just do it yourself:
            </p>
            <ol>
              <li>Pour a small amount of water into a narrow jar (an olive jar works great) and mark the water level with a Sharpie.</li>
              <li>Add the fuel to around a 10:1 fuel:water ratio.</li>
              <li>Shake well, let settle for a few minutes.</li>
              <li>See if the water level has risen above the mark. If it has risen, it has combined with ethanol in the fuel.</li>
            </ol>

            <h2>Is pure gas more expensive than E10?</h2>
            <p>
              Yes, it typically is, often due to federal and state tax incentives. We buy it because we want to fuel our vehicles with it.
              If you want to save money on gas, this site is of no use to you - it does NOT have current gasoline prices on it.
              They vary from day to day and this site isn't about saving money. It's about finding pure gasoline for your machine.
            </p>

            <h2>Why aren't prices posted?</h2>
            <p>
              This site isn't about prices. If you want to know the price, call the station or look it up on a price site.
              This site is about one thing: where to find pure gas in Canada and the US, and it is maintained by the users and only the users.
            </p>

            <h2>Does pure gas give me better mileage?</h2>
            <p>
              Yes, pure gas typically gives slightly better mileage than E10, and much better than E85, simply because gasoline has higher free energy than ethanol.
              The free energy of gasoline is 34.2 MJ per liter. The free energy of ethanol is 24.0 MJ per liter. That means E10 (10% ethanol) has a free energy of 33.2 MJ per liter,
              and E85 (85% ethanol) has a free energy of 25.6 MJ per liter. As a result, your mileage is reduced by 3% with E10 over pure gas, and 25% with E85 over pure gas,
              all else being equal. Mileage will be reduced even more if your engine doesn't run as well on E10, which is often the case with older vehicles.
            </p>

            <h2>What about ethanol politics?</h2>
            <p>
              This site doesn't get into the political side of ethanol in gasoline. This site has one, simple purpose: to list the stations that sell ethanol-free gasoline in the U.S. and Canada.
              Period. With that said, a good starting point for the anti-ethanol campaign in the U.S. is Dean Billings' site flyunleaded.com, an authoritative reference on ethanol legislation 
              at the federal and state levels. Dean keeps up on the issues and shares his knowledge with us and comments regularly here. Check his site out!
            </p>

            <h2>Who runs this site?</h2>
            <p>
              My name is Sam Hokin, and I'm a BMW motorcycle rider, programmer, physicist, and computational scientist.
              After searching around, I came to the conclusion that a site like this didn't exist, and it should.
              Since I know how to write and host database-backed web sites, I thought I'd go ahead and do it myself.
              If you'd like to contact me, send me an email: sam@bsharp.org.
            </p>

            <h2>Who maintains the data on this site?</h2>
            <p>
              YOU DO. The station listings on this site are added, updated and removed by users like you.
              However, we should all thank Jonathan Lathbury for his hard work tracking down phone numbers, updating GPS coordinates, and doing other things to vastly improve 
              the quality of the pure-gas.org listings. Thanks, Jonathan!
            </p>

            <h2>What's this site's business model?</h2>
            <p>
              There is no business model. I pay for it out of my own pocket using a Google Cloud virtual server.
            </p>

            <h2>Who took that cool picture of the old pump in the header?</h2>
            <p>
              I grabbed that photo from sarowen's Flickr site; she marked it with Creative Commons licensing which allows me to use it for non-commercial purposes.
            </p>
        </div>
    );
}
