export function POIPage() {
    return (
        <div className="content">
            <h1>POI file download</h1>
            <p>
                Find below a link to a zipped POI (CSV) file, which you may be able to load into your Garmin or other GPS device. You will need to unzip it first.
            </p>
            <ul>
                <li>
                    <a href="/download/pure-gas-poi.csv.zip"><b>Gzipped POI (CSV) file</b></a>
                </li>
            </ul>
        </div>
    );
}
