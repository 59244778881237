import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { UPDATE_STATION } from "../graphql/mutations";
import { OCTANES } from "../util/octanes";
import { STATES_MAP } from "../util/statesmap";

import { BrandSelector } from "./BrandSelector";
import { ErrorBox } from "./ErrorBox";
import { StationMap } from "./StationMap";
import { StationUpdates } from "./StationUpdates";

export function StationUpdateForm({ state, station, onCancel, reactivate }) {
    const { register, handleSubmit } = useForm();
    // update station
    const [updateStation, { data, loading, error }] = useMutation(UPDATE_STATION);
    if (loading) return (<div className="loading"><b>Updating station...</b></div>);
    if (error) return (<ErrorBox error={error} />);
    const onSubmit = (d) => {
        // get the non-empty octanes, use alert box if none provided
        const octaneList = [];
        parseInt(d.octane0) && octaneList.push(parseInt(d.octane0));
        parseInt(d.octane1) && octaneList.push(parseInt(d.octane1));
        parseInt(d.octane2) && octaneList.push(parseInt(d.octane2));
        if (octaneList.length===0) {
            alert("You must select at least one octane rating.");
        }
        if (octaneList.length>0) {
            updateStation({
                variables: {
                    id: station.id,
                    author: d.author,
                    comment: d.comment,
                    name: d.name,
                    streetaddress: d.streetaddress,
                    city: d.city,
                    statecode: d.statecode,
                    phone: d.phone,
                    brandId: parseInt(d.brand_id),
                    octanes: octaneList,
                    latitude: parseFloat(d.latitude),
                    longitude: -parseFloat(d.longitude),
                    gpscomment: d.gpscomment,
                }
            });
        }
    }
    // refresh station
    if (data) {
        station = data.updateStation.station;
        if (data.updateStation.station.removed) {
            reactivate = true;
        }
    }
    return (
        <div>
            {data && !data.updateStation.station.removed && !reactivate &&
             <div>
                 <div className="message">
                     Thanks for updating this station!<br/>You may need to refresh your browser<br/>to see the updated listing.
                 </div>
                 {data.updateStation.station.location.latitude>0 &&
                  <StationMap station={data.updateStation.station} />
                 }
             </div>
            }
            {data && !data.updateStation.station.removed && reactivate && 
             <div>
                 <div className="message">
                     Thanks for reactivating this station!<br/>Refresh the page to see it reappear in the {state.name} listing.
                 </div>
                 {data.updateStation.station.location.latitude>0 &&
                  <StationMap station={data.updateStation.station} />
                 }
             </div>
            }
            {(!data || data.updateStation.station.removed) && reactivate && 
             <div className="reactivate">
                 The station you just updated or added was previously removed at this address.
                 If you wish to reactivate it, enter any updates in the form below and hit <span className="update"><b>UPDATE STATION</b></span>.
                 {station.updates.length>0 && 
                  <StationUpdates updates={station.updates} />
                 }
                 <div className="station-updates">
                     <ul>
                         <li><b>{station.timeposted} CT added by {station.author}</b>{station.comment && <span>: <i>{station.comment}</i></span>}</li>
                     </ul>
                 </div>
             </div>
            }
            {(!data || data.updateStation.station.removed) &&
             <form onSubmit={ handleSubmit(onSubmit) } className="station-form update-trim">
                 <label>
                     Brand<br/>
                     <BrandSelector register={register} station={station} />
                 </label>
                 <label>
                     Station name<br/>
                     <input {...register("name")} defaultValue={station.name} required />
                 </label>
                 <label>
                     Street address <span className="normal">(number and street only)</span><br/>
                     <input {...register("streetaddress")} defaultValue={station.streetaddress} required />
                 </label>
                 <label>
                     City/Town <span className="normal">(without state or zip code)</span> {state && <span>in {state.name}</span>} <br/>
                     <input {...register("city") } defaultValue={station.city} required />
                 </label>
                 {!state &&
                  <label>
                      State <br/>
                      <select {...register("statecode")} defaultValue={station.state.code} required >
                          {[...STATES_MAP].map(([key, value]) => (
                              <option key={key} value={key}>{value.name}</option>
                          ))}
                      </select>
                  </label>
                 }
                 <label>
                     <span className="normal">Phone</span><br/>
                     <input className="phone-input" {...register("phone") } defaultValue={station.phone} /> <span className="normal">format: 123-456-7890</span>
                 </label>
                 <label>
                     Octanes<br/>
                     <select {...register("octane0")} defaultValue={station.octanes[0]} required >
                         <option value="0">--</option>
                         {OCTANES.map(octane => (
                             <option key={octane} value={octane}>{octane}</option>
                         ))}
                     </select>
                     <span className="spacer"/>
                     <select {...register("octane1")} defaultValue={station.octanes[1]}>
                         <option value="0">--</option>
                         {OCTANES.map(octane => (
                             <option key={octane} value={octane}>{octane}</option>
                         ))}
                     </select>
                     <span className="spacer"/>
                     <select {...register("octane2")} defaultValue={station.octanes[2]}>
                         <option value="0">--</option>
                         {OCTANES.map(octane => (
                             <option key={octane} value={octane}>{octane}</option>
                         ))}
                     </select>
                 </label>
                 {station.location ?
                  <label>
                      <span className="normal">GPS</span><br/>
                      <input {...register("latitude")} defaultValue={station.location.latitude} className="gps-input"/> <span className="normal">N</span>
                      <span className="spacer" />
                      <input {...register("longitude")} defaultValue={-station.location.longitude} className="gps-input" /> <span className="normal">W (positive number)</span>
                      <br/>
                      <span className="normal">Update this comment if you change GPS coordinates</span>
                      <br/>
                      <input {...register("gpscomment")} defaultValue={station.location.comment} />
                  </label>
                  :
                  <label>
                      <span className="normal">GPS</span><br/>
                      <input {...register("latitude")} className="gps-input"/> <span className="normal">N</span>
                      <span className="spacer" />
                      <input {...register("longitude")} className="gps-input" /> <span className="normal">W (positive number)</span>
                      <br/>
                      <input {...register("gpscomment")} />
                  </label>
                 }
                 <label>
                     Your name<br/>
                     <input {...register("author")} required />
                 </label>
                 <label>
                     Please enter a comment:<br/>
                     <textarea {...register("comment")} required></textarea>
                     <div className="buttons">
                         <button className="update">UPDATE STATION</button>
                         <span className="spacer"/>
                         <button className="cancel" onClick={onCancel}>CANCEL</button>
                     </div>
                 </label>
             </form>
            }
        </div>
    );
}
