import { useQuery } from "@apollo/client";
import { useParams } from 'react-router-dom';
import { GET_STATES } from '../graphql/queries';
import { STATES_MAP } from "../util/statesmap";
import { ErrorBox } from "./ErrorBox";
import { ReactLeafletMap } from "./ReactLeafletMap";

export function MapList({ stateCode, onStateClicked }) {
    // handle state code on URI
    var { id } = useParams();
    if (id) stateCode = id;
    // we query states to ignore states that have zero stations
    const { loading, error, data } = useQuery(GET_STATES);
    if (loading) return <div className="loading">Loading states...</div>;
    if (error) return <ErrorBox error={error} />;
    // pass stateInfo to map component
    const stateInfo = STATES_MAP.get(stateCode);
    return (
        <div className="content space-top">
            {!id &&
             <div className="state-list">
                 {data.states.map(state => (
                     state.numStations > 0 &&
                         <button title={state.name} key={state.code} value={state.code} onClick={onStateClicked}>{state.code}</button>
                 ))}
             </div>
            }
            <ReactLeafletMap stateInfo={stateInfo} />
        </div>
    );
}

