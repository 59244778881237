export function KMLPage() {
    return (
        <div className="content">
            <h1>KML file download</h1>
            <p>
                This page contains links to download KML files, the raw and compressed versions, which you can load into a mapping program like Google Earth to view station locations.
                Only stations with latitude and longitude values will appear.
            </p>
            <ul>
                <li>
                    <a href="/download/pure-gas.kmz"><b>KMZ file</b></a> - the KMZ file is the compressed KML file, for a much faster download. It is supported by most map viewers.
                </li>
                <li>
                    <a href="/download/pure-gas.kml"><b>KML file</b></a> - this is the uncompressed KML file; download for viewers which do not accept KMZ files, which is unlikely.
                </li>
            </ul>
        </div>
    );
}
