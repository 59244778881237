import {
  gql
} from "@apollo/client";

export const REMOVE_STATION = gql`
mutation RemoveStation(
  $id: ID!,
  $author: String!,
  $comment: String!
) {
  removeStation(
    id: $id,
    author: $author,
    comment: $comment
  ) {
    station {
      id
      name
      streetaddress
      city
      state {
        code
        name
      }
      phone
      brand {
        id
        name
      }
      location {
        latitude
        longitude
        comment
      }
      octanes
      timeposted
      removed
      comment
      author
      updates {
        id
        removal
        comment
        author
        timeupdated
      }
    }
    errors {
      status
      title
      details
    }
  }
}
`

export const UPDATE_STATION=gql`
mutation UpdateStation(
  $id: ID!,
  $author: String!,
  $comment: String!,
  $name: String!,
  $streetaddress: String!,
  $city: String!,
  $statecode: String,
  $brandId: Int!,
  $phone: String,
  $latitude: Float,
  $longitude: Float,
  $gpscomment: String,
  $octanes: [Int]
) {
  updateStation(
    id: $id,
    author: $author,
    comment: $comment,
    name: $name,
    streetaddress: $streetaddress,
    city: $city,
    statecode: $statecode,
    phone: $phone,
    brand_id: $brandId,
    latitude: $latitude,
    longitude: $longitude,
    gpscomment: $gpscomment,
    octanes: $octanes,
  ) {
    station {
      id
      name
      streetaddress
      city
      state {
        code
        name
      }
      phone
      brand {
        id
        name
      }
      location {
        latitude
        longitude
        comment
      }
      octanes
      timeposted
      removed
      comment
      author
      updates {
        id
        removal
        comment
        author
        timeupdated
      }
    }
    errors {
      status
      title
      details
    }
  }
}
`;

export const ADD_STATION=gql`
mutation AddStation(
  $name: String!,
  $streetaddress: String!,
  $city: String!,
  $stateCode: String!,
  $brandId: Int!,
  $octanes: [Int],
  $author: String!,
  $phone: String,
  $latitude: Float,
  $longitude: Float,
  $gpscomment: String,
  $comment: String
) {
  addStation(
    name: $name,
    streetaddress: $streetaddress,
    city: $city,
    statecode: $stateCode,
    phone: $phone,
    brand_id: $brandId,
    octanes: $octanes,
    author: $author,
    comment: $comment,
    latitude: $latitude,
    longitude: $longitude,
    gpscomment: $gpscomment
  ) {
    station {
      id
      name
      streetaddress
      city
      state {
        code
        name
      }
      phone
      brand {
        id
        name
      }
      location {
        latitude
        longitude
      }
      octanes
      timeposted
      removed
      comment
      author
      location {
        latitude
        longitude
        comment
      }
      updates {
        id
        removal
        comment
        author
        timeupdated
      }
    }
    errors {
      status
      title
      details
    }
  }
}
`;
