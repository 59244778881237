import { useQuery } from "@apollo/client";

import { GET_STATION_LIST } from "../graphql/queries";

import { ErrorBox } from "./ErrorBox";
import { StationListHeader } from "./StationListHeader";
import { StationListRow } from "./StationListRow";
import { StationsLoading } from "./StationsLoading";

// the entire list of stations
export function StationList({ selectedState, stationId,
                              shownComponents, onViewButtonClicked,
                              onAddButtonClicked, onUpdateButtonClicked, onRemoveButtonClicked, onCancel,
                              checkedStations, onStationChecked, onPrintStationsClicked }) {
    // get the active stations for this state
    const { loading, error, data } = useQuery(GET_STATION_LIST, {
        variables: { code: selectedState.code }
    });
    if (loading) return ( <StationsLoading state={selectedState} /> );
    if (error) return ( <ErrorBox error={error} /> );
    const stations = data.stationsByState;
    return (
        <div>
            {checkedStations.length>0 &&
             <button onClick={onPrintStationsClicked}>print selected stations</button>
            }
            <StationListHeader showCheckbox={true} />
            {stations.map(station => (
                <StationListRow key={station.id} state={selectedState} station={station} stationId={stationId}
                                shownComponents={shownComponents} onViewButtonClicked={onViewButtonClicked}
                                onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel}
                                onStationChecked={onStationChecked} />
            ))}
        </div>
    );
}
